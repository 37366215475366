import { StyledFlex } from "../../assets/styles/flex.styled";
import styled from "styled-components";
import { IconButton } from "../../components/IconButton";
import { Button } from "../../components/Button";

export const PageWrapper = styled(StyledFlex)`
  box-sizing: border-box;
`;

export const ContentWrapper = styled.div`
  padding: 24px;
  background-color: #f5f5f5;
  flex: 1;
`;

export const MainContent = styled(StyledFlex)`
  max-width: 1400px;
  box-sizing: border-box;
  height: 100%;
  flex-direction: column;
  margin: auto;
  padding: 8px;
  background-color: var(--colors-white);
`;

export const PageTitle = styled.h4`
  margin: 0;
  font-weight: 600;
  color: var(--colors-outer-space);
`;

export const InfoText = styled.span`
  font-size: 12px;
  color: var(--colors-gray-500);
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 400px;
  height: 36px;
  border-radius: 8px;
  border: 1px solid var(--colors-slate-500);

  &:focus-within {
    border: 1px solid #2aaab6;

    svg:first-child path {
      fill: var(--colors-eastern-blue);
    }
  }

  svg:first-child {
    height: 16px;
    width: 16px;
    margin-left: 12px;

    path {
      fill: #c5c5c5;
    }
  }

  input {
    border: none;
    outline: none;
    background-color: transparent;
    padding-left: 14px;

    &::placeholder {
      color: #94a3b8;
      font-weight: 400;
      font-size: 14px;
    }
  }

  input:focus {
    border: none;
    outline: none;
  }

  svg:last-child {
    margin-right: 12px;
    cursor: pointer;

    :hover {
      path {
        fill: var(--colors-eastern-blue);
      }
    }
  }
`;

export const RemoveButton = styled(IconButton)`
  background-color: var(--colors-slate-100);
  color: var(--colors-slate-900);
  border: none;

  svg path {
    fill: var(--colors-slate-900);
  }

  &[disabled] {
    background-color: var(--colors-slate-100);
    color: var(--colors-slate-400);

    svg path {
      fill: var(--colors-slate-400);
    }
  }
`;

export const CtaButton = styled(Button)`
  padding: 5px 25px;
  font-size: 16px;
  line-height: 24px;
  border: none;

  &[disabled] {
    border: none;
    background-color: var(--colors-slate-100);
    color: var(--colors-slate-400);
  }
`;

export const FilterButton = styled(IconButton)`
  border: none;
  font-weight: 400;
  color: var(--colors-slate-500);

  svg {
    fill: var(--colors-slate-500);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
