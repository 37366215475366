import { useQuery } from "react-query";
import ApiClient from "../../../utils/apiClient";
import { dashboardDataPairs } from "../constants";
import { ReportsResponseDto } from "../../reports/types";

export const useDashboardReportsData = ({ programId }: { programId?: string }) => {
  return useQuery(
    ["dashboard-data", programId],
    () =>
      ApiClient.post<ReportsResponseDto>("/reports/execute", {
        reportIds: dashboardDataPairs.map((item) => item.id),
        parameters: { programId },
      }).then((res) => res.data),
    {
      enabled: Boolean(programId),
      refetchOnWindowFocus: "always",
    }
  );
};
