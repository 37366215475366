import { OVERLAY_LAYERS } from "../../../stores/MapStore";
import clearanceImage from "../../../assets/images/clearance.png";
import encroachmentsImage from "../../../assets/images/encroachments.png";
import feederLinesImage from "../../../assets/images/feeder_lines.png";
import roadsImage from "../../../assets/images/roads.png";
import regions from "../../../assets/images/regions.png";
import urbanAreas from "../../../assets/images/urban_areas.png";

export const INACTIVE_AREA = {
  weight: 1.5,
};

export const ACTIVE_FEEDER_AREA = {
  weight: 3,
  color: "var(--colors-teal-3)",
  fillOpacity: 0,
};

export const TRANSPARENT = {
  fillOpacity: 0,
  weight: 0,
};

export const SELECTED_FRAME_STYLE = {
  opacity: 1,
  weight: 2,
  color: "var(--colors-white)",
  dashArray: "2, 6",
  fillColor: "transparent",
};

export const PROPERTY_KEY_SELECTED = "selected-key";

export const PROPERTY_REGION_KEY_SELECTED = "selected-region";

export const ZOOM_LEVEL_LIMIT = 15;

export const MAX_ZOOM_LEVEL = 20;

export const MIN_ZOOM_LEVEL_FOR_SPAN_WITH_WORK_TYPE = 16;

export const MIN_ZOOM_LEVEL_FOR_HAZARD_TREES_LAYER = 18;

export const MIN_ZOOM_LEVEL_FOR_SELECTION_TOOLS = 14;

export const MIN_ZOOM_LEVEL_FOR_MA_LAYER = 14;

export const MIN_ZOOM_LEVEL_FOR_COMMENTS_LAYER = 10;

export const MIN_ZOOM_LEVEL_FOR_ALERTS_LAYER = 10;

export const MAX_ZOOM_LEVEL_FOR_REGION_LAYER = 14;

export const INITIAL_REGION_LEVEL_LAYERS = [
  { name: OVERLAY_LAYERS.REGIONS, key: OVERLAY_LAYERS.REGIONS, checked: true, image: regions },
];

export const INITIAL_FEEDER_LEVEL_LAYERS = [
  { name: OVERLAY_LAYERS.CLEARANCE, key: OVERLAY_LAYERS.CLEARANCE, checked: true, image: clearanceImage },
  {
    name: OVERLAY_LAYERS.ENCROACHMENTS,
    key: OVERLAY_LAYERS.ENCROACHMENTS,
    checked: true,
    image: encroachmentsImage,
  },
  { name: OVERLAY_LAYERS.FEEDERS, key: OVERLAY_LAYERS.FEEDERS, checked: true, image: feederLinesImage },
  { name: OVERLAY_LAYERS.ROADS, key: OVERLAY_LAYERS.ROADS, checked: false, image: roadsImage },
  { name: OVERLAY_LAYERS.URBAN_AREAS, key: OVERLAY_LAYERS.URBAN_AREAS, checked: false, image: urbanAreas },
];

export const URBAN_AREAS_Z_INDEX = 396;

export const CLEARANCE_Z_INDEX = 397;

export const FEEDERS_Z_INDEX = 400;

export const SPANS_Z_INDEX = 400;

export const REGIONS_Z_INDEX = 400;

export const ENCROACHMENTS_Z_INDEX = 398;

export const COUNTY_LABELS_Z_INDEX = 401;

export const HOVERED_OBJECT_CLASS_PREFIX = "objectId-";

export const ADDITIONAL_LAYERS_Z_INDEX = 395;

export const ADDITIONAL_OVERLAYS_Z_INDEX = 420;

export const CUT_PLAN_Z_INDEX = 4000;

export const LAYERS_MENU_BUTTON_SIZE = 75;

export const RISK_VIEW_BUTTON_WIDTH = 133;

export const MAP_OPACITY_CONTROL_OFFSET = 50;

export const LEGEND_BOTTOM_PADDING = 25;
