import { ReportsResponseDto, DashboardNodeDto } from "../reports/types";
import { StatusesType } from "./types";

export function shouldShowCta(status: StatusesType) {
  switch (status) {
    case "Unscheduled":
    case "Plan":
    case "Unassigned":
    case "GF Assigned":
      return true;
    default:
      return false;
  }
}
export function getCtaLabel(status: StatusesType) {
  switch (status) {
    case "Unscheduled":
      return "Add to Plan";
    case "Plan":
      return "Generate Work Orders";
    case "Unassigned":
      return "Assign to GF";
    case "GF Assigned":
      return "Assign to Crew";
    case "Rework":
      return "Review";
    default:
      return "";
  }
}

export const getTaxonomyItemByLevel = (level: number) => {
  if (level === 1) {
    return "regionId";
  }

  if (level === 2) {
    return "feederId";
  }

  return "spanId";
};

export function filterBySearchTerm(item: DashboardNodeDto, searchText: string) {
  if (searchText === "") return true;

  if (item.label.toLowerCase().includes(searchText.toLowerCase())) {
    return true;
  }

  return false;
}

export function mapTableData(data: ReportsResponseDto | undefined) {
  const finaldata = data?.results.reduce((acc, value) => {
    acc[value.reportName as StatusesType] = value.data as DashboardNodeDto[];

    return acc;
  }, {} as Record<StatusesType, DashboardNodeDto[]>);

  return finaldata;
}

export function filterByVoltage(voltage: number, min: number | undefined, max: number | undefined) {
  if (min === undefined && max !== undefined) {
    return voltage <= max;
  }

  if (min !== undefined && max === undefined) {
    return voltage >= min;
  }

  if (min !== undefined && max !== undefined) {
    return voltage >= min && voltage <= max;
  }

  return true;
}
