import { Backdrop } from "../../../../components/Backdrop";
import { IconButton } from "../../../../components/IconButton";
import { Portal } from "../../../../components/Portal";
import { Header, Title, Wrapper, Content } from "./Modal.styled";
import { ReactComponent as CloseIcon } from "../../../../assets/images/close.svg";

interface IProps {
  title: string;
  width: string;
  isHidden: boolean;
  closeModal: () => void;
  children: React.ReactNode | React.ReactNode[];
}

export const Modal = ({ title, width, isHidden, closeModal, children }: IProps) => {
  return (
    <Portal selector="layer-menu-root">
      <Backdrop isHidden={isHidden} setIsHidden={closeModal}>
        <Wrapper width={width}>
          <Header>
            <Title>{title}</Title>
            <IconButton
              data-testid="modalCloseButton"
              Icon={CloseIcon}
              className="close"
              onClick={closeModal}
              border="none"
            />
          </Header>
          {children}
        </Wrapper>
      </Backdrop>
    </Portal>
  );
};

Modal.Content = Content;
