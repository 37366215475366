import { useEffect, useMemo, useState } from "react";
import { CellProps, Column } from "react-table";
import { StyledFlex } from "../../../assets/styles/flex.styled";
import { ReactComponent as MapIcon } from "../../../assets/images/go-to-map.svg";
import { ReactComponent as ViolationImg } from "../../../assets/images/violation.svg";
import { ReactComponent as ChevronDown } from "../../../assets/images/icon_chevron_down.svg";
import { RISK_PRIORITY } from "../../../types/responses";
import { getForecastRoute } from "../../../routes";
import {
  Expander,
  CellSkeleton,
  GotoMapButton,
  HoverWrapper,
  WorktypeCount,
  LastWorktypeWrapper,
} from "../components/TaxonomyNodesTable/TaxonomyNodesTable.styled";
import { Checkbox } from "../../../components/Checkbox";
import { FeederCircleStatusStyled } from "../../planning-mode/components/ProgramsForPlan/ProgramsForPlan.styled";
import { getWorkTypeImage } from "../../../pages/MapScreen/MapScreen.utils";
import { useHistory } from "react-router-dom";
import { useTaxonomyNodeParents } from "./useTaxonomyNodeParents";
import { Spinner } from "../../../components/Loader/Spinner";
import { DashboardNodeDto } from "../../reports/types";
import { getTaxonomyItemByLevel } from "../utils";
import { SORT_ORDER } from "../../star-command/utils";
import { roundValue } from "../../../utils/unitHelper";
import { VOLTAGE_UNIT_SHORT_NAME } from "../../../constants";
import { formatDate } from "../../../utils/dateUtils";

const WORKTYPES_LIMIT = 3;

interface IProps {
  isLoading: boolean;
}

export const useCircuitColumns = ({ isLoading }: IProps) => {
  const history = useHistory();
  const [selectedTaxonomyNode, setSelectedTaxonomyNode] = useState<DashboardNodeDto | undefined>(undefined);
  const {
    data: taxonomyPathData,
    isLoading: isPathLoading,
    isSuccess,
  } = useTaxonomyNodeParents(selectedTaxonomyNode?.taxonomyNodeId);

  useEffect(() => {
    if (isSuccess) {
      const lastUrlParam = `${getTaxonomyItemByLevel(taxonomyPathData.maxLevel + 1)}=${selectedTaxonomyNode?.objectId}`;
      history.push(`${getForecastRoute()}?${taxonomyPathData.taxonomyNodeUrl}&${lastUrlParam}`);
    }
  }, [isSuccess, taxonomyPathData, history, selectedTaxonomyNode?.objectId]);

  const columns: Column<DashboardNodeDto>[] = useMemo(() => {
    const cols: Column<DashboardNodeDto>[] = [
      {
        id: "expander",
        width: 45,
        minWidth: 45,
        disableFilters: true,
        disableSortBy: true,
        disableResizing: true,
        Cell: ({ row, isLoading, handleExpand }: CellProps<DashboardNodeDto, any>) => {
          return row.depth === 0 ? (
            <Expander
              {...row.getToggleRowExpandedProps()}
              className={row.isExpanded ? "is-expanded" : undefined}
              data-testid={`expand circuit ${row.original.taxonomyNodeId}`}
              onClick={() => handleExpand(row)}
              disabled={isLoading}
            >
              {isLoading ? <Spinner width="9px" height="9px" m={0} /> : <ChevronDown />}
            </Expander>
          ) : null;
        },
      },
      {
        id: "checkbox",
        width: 45,
        minWidth: 45,
        disableSortBy: true,
        disableFilters: true,
        disableResizing: true,
        Header: ({ getToggleAllRowsSelectedProps }) => {
          return <Checkbox id={"select all"} data-testid={`circuit select all`} {...getToggleAllRowsSelectedProps()} />;
        },
        Cell: ({ row }: CellProps<DashboardNodeDto, any>) => {
          return (
            <Checkbox
              id={row.original.objectId}
              partialyChecked={row.depth === 0 && row.isSomeSelected}
              data-testid={`circuit ${row.original.objectId}`}
              {...row.getToggleRowSelectedProps()}
            />
          );
        },
      },
      {
        accessor: "label",
        maxWidth: 230,
        disableFilters: true,
        disableResizing: true,
        Header: () => "CIRCUIT",
        Cell: ({ value }) => (
          <span className="truncText" title={value}>
            {value ?? ""}
          </span>
        ),
      },
      {
        accessor: "region",
        Header: "REGION",
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }) => value ?? "",
      },
      {
        accessor: "riskPriority",
        Header: "PRIORITY",
        width: 75,
        disableResizing: true,
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <FeederCircleStatusStyled data-priority={value} title={value}>
              {value === RISK_PRIORITY.VIOLATION && <ViolationImg width={15} height={15} />}
            </FeederCircleStatusStyled>
          );
        },
        sortType: (rowA, rowB) => {
          const { riskPriority: riskPriorityA } = rowA.original;
          const { riskPriority: riskPriorityB } = rowB.original;

          if (SORT_ORDER[riskPriorityA] > SORT_ORDER[riskPriorityB]) return 1;

          return -1;
        },
      },
      {
        id: "voltage",
        accessor: "voltage",
        Header: "VOLTAGE",
        width: 75,
        disableResizing: true,
        Cell: ({ value }) => `${roundValue(Number(value), 2)} ${VOLTAGE_UNIT_SHORT_NAME}`,
      },
      {
        accessor: "vendors",
        Header: "VENDOR",
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }) => (
          <span className="truncText" title={value}>
            {value ?? "-"}
          </span>
        ),
      },
      {
        accessor: "progress",
        Header: "PROGRESS",
        disableResizing: true,
        disableFilters: true,
        width: 150,
        Cell: ({ value }) => {
          return (
            <StyledFlex gap={"4px"} alignItems={"center"}>
              <StyledFlex background={"#EFF3F6"} height={"5px"} borderRadius={"10px"} width={"80px"}>
                <StyledFlex background={"#2AAAB6"} borderRadius={"10px"} width={`${value}%`} />
              </StyledFlex>
              <span>{value ?? 0} %</span>
            </StyledFlex>
          );
        },
      },
      {
        accessor: "workTypes",
        Header: "PLANNED WORK",
        disableSortBy: true,
        disableFilters: true,
        disableResizing: true,
        maxWidth: 200,
        Cell: ({ value: worktypes = [] }) => {
          const firstThreeWorktypes = (worktypes ?? []).slice(0, WORKTYPES_LIMIT);
          const restWorktypes = (worktypes ?? []).slice(WORKTYPES_LIMIT, -1);

          return (
            <StyledFlex alignContent={"flex-end"} position={"relative"}>
              {firstThreeWorktypes.map((wtItem) => (
                <img
                  key={wtItem.id}
                  src={wtItem.iconUrl ?? getWorkTypeImage(wtItem.code)}
                  title={wtItem.name}
                  alt={wtItem.name}
                  width={"28px"}
                  height={"28px"}
                />
              ))}
              {restWorktypes.length > 0 && (
                <WorktypeCount>
                  +{restWorktypes.length}
                  <HoverWrapper gap={"5px"}>
                    {restWorktypes.map((wtItem) => {
                      return (
                        <StyledFlex alignItems={"center"} key={wtItem.id}>
                          <img
                            key={wtItem.id}
                            src={wtItem.iconUrl ?? getWorkTypeImage(wtItem.code)}
                            title={wtItem.name}
                            alt={wtItem.name}
                            width={"28px"}
                            height={"28px"}
                          />
                          <span>{wtItem.name}</span>
                        </StyledFlex>
                      );
                    })}
                  </HoverWrapper>
                </WorktypeCount>
              )}
            </StyledFlex>
          );
        },
      },
      {
        accessor: "lastVisited",
        Header: "LAST VISITED",
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value, row }) => {
          const { lastVisitInfo } = row.original;
          const hasData = Boolean(lastVisitInfo);

          return (
            <LastWorktypeWrapper className={hasData ? "hasData" : ""}>
              <span>{formatDate(value ?? "")}</span>
              {hasData && (
                <HoverWrapper gap={"0"}>
                  <strong>Last Visited</strong>
                  {lastVisitInfo?.workTypeName && (
                    <div>
                      <strong>Work Type:&nbsp;</strong>
                      <span>{lastVisitInfo?.workTypeName}</span>
                    </div>
                  )}
                  {lastVisitInfo?.vendorName && (
                    <div>
                      <strong>Vendor:&nbsp;</strong>
                      <span>{lastVisitInfo?.vendorName}</span>
                    </div>
                  )}
                  {lastVisitInfo?.crewName && (
                    <div>
                      <strong>Crew:&nbsp;</strong>
                      <span>{lastVisitInfo?.crewName}</span>
                    </div>
                  )}
                </HoverWrapper>
              )}
            </LastWorktypeWrapper>
          );
        },
      },
      {
        accessor: "year",
        Header: "PLANNED YEAR",
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }) => value ?? "-",
      },
      {
        accessor: "taxonomyNodeId",
        Header: "",
        width: 50,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value, row }) => {
          return (
            <GotoMapButton onClick={() => setSelectedTaxonomyNode(row.original)}>
              {selectedTaxonomyNode?.taxonomyNodeId === value && isPathLoading ? <Spinner /> : <MapIcon />}
            </GotoMapButton>
          );
        },
      },
    ];

    return isLoading
      ? cols.map((column) => ({
          ...column,
          Cell: <CellSkeleton />,
        }))
      : cols;
  }, [isLoading, isPathLoading, selectedTaxonomyNode]);

  return columns;
};
