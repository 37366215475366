export const dashboardDataPairs = [
  {
    id: "2bad388a-807c-4fa9-a7e2-3d189cb3420a",
    name: "Dashboard - Unscheduled",
  },
  {
    id: "5be2f871-4587-4f71-a504-2e16da29bab5",
    name: "Dashboard - Scheduled",
  },
  {
    id: "fe5facc9-6c7e-4df2-9d62-7e6155a96de3",
    name: "Dashboard - Assess",
  },
  {
    id: "fc6f79d0-98cb-413f-8e36-0c6e39a4b04f",
    name: "Dashboard - Plan",
  },
  {
    id: "45111b77-aa6a-4202-8f5e-a33abf4b8f32",
    name: "Dashboard - Permissioning",
  },
  {
    id: "6bff8b5a-c3d3-4bbe-8679-9cc682f6867e",
    name: "Dashboard - Unassigned",
  },
  {
    id: "1152b726-e5e5-4df4-bf4e-3919fb76716f",
    name: "Dashboard - Vendor Assigned",
  },
  {
    id: "ee7c02d9-11d3-495b-908a-b05b29690f4b",
    name: "Dashboard - Crew Assigned",
  },
  {
    id: "c9dfaffd-2def-4e00-86ea-7f037f10cc0b",
    name: "Dashboard - In progress",
  },
  {
    id: "19a44b8a-05e7-400c-b5be-0a5d493bc545",
    name: "Dashboard - On-hold",
  },
  {
    id: "a50ddada-9ffc-4921-ac7d-2de5276fa5d7",
    name: "Dashboard - QC",
  },
  {
    id: "9a5ab9e2-5ad7-4c9f-81ec-82fa3730eb56",
    name: "Dashboard - Rejected",
  },
  {
    id: "3f18c8ae-ce0f-4cab-9fe5-30661d376758",
    name: "Dashboard - Completed",
  },
];

export const dashboardSpansDataPairs = [
  { id: "a5d9334d-214e-4441-8896-bb44aa80296e", name: "Assess" },
  { id: "c1da4d39-4ad0-4330-a11e-151eb87e104f", name: "Completed" },
  { id: "3d684f11-69ae-4695-992d-421043087bfc", name: "Crew Assigned" },
  { id: "08f8de2a-d543-447d-aae7-89ef9f6fcfd5", name: "GF Assigned" },
  { id: "14868e56-418b-4a13-9c4f-5dd71bcc428b", name: "In progress" },
  { id: "e588c1b5-17df-4bcc-bbd5-6d2ef2e7f3f9", name: "On-hold" },
  { id: "13ff8b58-33ee-4c93-98df-324aede8f2ab", name: "Permissioning" },
  { id: "453a24c5-185e-4677-805f-d540c849f59f", name: "Plan" },
  { id: "bc91ac9f-17df-4bec-abe4-5bd548cb56a3", name: "QC" },
  { id: "0a0a54e0-4e7b-44f8-a67c-ec26dc046697", name: "Rework" },
  { id: "d225e5a3-62aa-4f07-88fe-a574403b21f7", name: "Scheduled" },
  { id: "c07715ac-bcc5-46dd-8a1c-b1ef2a6eed2b", name: "Unassigned" },
  { id: "14a3e38c-0597-42f1-b200-479fae2e4338", name: "Unscheduled" },
];
